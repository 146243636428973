import React from 'react';
import { Lock, Users, Trash2 } from 'lucide-react';
import type { KnowledgeBase } from '../types/knowledgeBase';

interface KnowledgeBasePanelProps {
  title: string;
  setTitle: (title: string) => void;
  visibility: 'private' | 'team';
  setVisibility: (visibility: 'private' | 'team') => void;
  isCreating: boolean;
  knowledgeBases: KnowledgeBase[];
  selectedKb: number | null;
  onSelectKb: (kbId: number) => void;
  onCreateKb: () => void;
  onDeleteKb: (kb: KnowledgeBase) => void;
  loading: boolean;
  error: string | null;
  onRetry: () => void;
  hasTeamAccess?: boolean;
}

export const KnowledgeBasePanel: React.FC<KnowledgeBasePanelProps> = ({
  title,
  setTitle,
  visibility,
  setVisibility,
  isCreating,
  knowledgeBases,
  selectedKb,
  onSelectKb,
  onCreateKb,
  onDeleteKb,
  loading,
  error,
  onRetry
}) => {
  return (
    <div className="flex flex-col h-full bg-gray-800 rounded-lg border border-gray-700 p-4">
      <h2 className="text-xl font-semibold text-gray-200 mb-4">Knowledge Bases</h2>

      {error && (
        <div className="mb-4 p-3 bg-red-900/50 border border-red-800 text-red-200 rounded-lg flex justify-between items-center">
          <span>{error}</span>
          <button 
            onClick={onRetry}
            className="px-3 py-1 bg-red-800 hover:bg-red-700 text-red-100 rounded-md transition-colors"
          >
            Retry
          </button>
        </div>
      )}

      <div className="space-y-4 mb-4">
        <div className="flex gap-2">
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Knowledge Base Title"
            className="flex-1 px-4 py-2 bg-gray-700 text-gray-200 rounded-lg border border-gray-600 
                     placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 
                     focus:border-transparent disabled:opacity-50"
            disabled={isCreating}
          />
          <select
            value={visibility}
            onChange={(e) => setVisibility(e.target.value as 'private' | 'team')}
            className="w-32 px-4 py-2 bg-gray-700 text-gray-200 rounded-lg border border-gray-600 
                     focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent 
                     disabled:opacity-50"
            disabled={isCreating}
          >
            <option value="private">Private</option>
            <option value="team">Team</option>
          </select>
        </div>
        <button
          onClick={onCreateKb}
          disabled={isCreating || !title.trim()}
          className="w-full px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 
                   transition-colors disabled:opacity-50 disabled:cursor-not-allowed
                   flex items-center justify-center gap-2"
        >
          {isCreating ? (
            <>
              <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin" />
              <span>Creating...</span>
            </>
          ) : (
            'Create Knowledge Base'
          )}
        </button>
      </div>

      <div className="flex-1 overflow-y-auto border border-gray-700 rounded-lg bg-gray-900">
        {loading ? (
          <div className="flex items-center justify-center h-full text-gray-400">
            <div className="w-5 h-5 border-2 border-gray-400 border-t-transparent rounded-full animate-spin mr-2" />
            <span>Loading knowledge bases...</span>
          </div>
        ) : knowledgeBases.length === 0 ? (
          <div className="flex items-center justify-center h-full text-gray-400 p-4 text-center">
            No knowledge bases found. Create one to get started.
          </div>
        ) : (
          <div className="divide-y divide-gray-700">
            {knowledgeBases.map((kb) => (
              <div
                key={kb.kb_id}
                className={`flex items-center justify-between p-3 cursor-pointer
                          hover:bg-gray-700 transition-colors
                          ${selectedKb === kb.kb_id ? 'bg-gray-700' : ''}`}
                onClick={() => onSelectKb(kb.kb_id)}
              >
                <div className="flex items-center gap-3 flex-1 min-w-0">
                  {kb.visibility === 'team' ? (
                    <Users size={16} className="flex-shrink-0 text-blue-400" />
                  ) : (
                    <Lock size={16} className="flex-shrink-0 text-gray-400" />
                  )}
                  <span className="text-gray-200 truncate">{kb.title}</span>
                  {kb.team?.name && (
                    <span className="px-2 py-1 text-xs bg-blue-900/50 text-blue-200 rounded-full whitespace-nowrap">
                      {kb.team.name}
                    </span>
                  )}
                </div>
                {kb.permissions?.canDelete && (
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      onDeleteKb(kb);
                    }}
                    className="p-1.5 text-gray-400 hover:text-red-400 hover:bg-red-900/20
                             rounded-md transition-colors ml-2"
                    disabled={loading}
                  >
                    <Trash2 size={16} />
                  </button>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};