// src/components/TeamMemberList.tsx
import React from 'react';
import { Crown, Shield, Users } from 'lucide-react';
import { APIService } from '../services/APIService';
import type { TeamResponse } from '../types/team';

interface TeamMemberListProps {
  team: TeamResponse;
  refreshTeams: () => void;
  isProcessing: boolean;
  setIsProcessing: React.Dispatch<React.SetStateAction<boolean>>;
}

export const TeamMemberList: React.FC<TeamMemberListProps> = ({ 
  team, 
  refreshTeams, 
  isProcessing, 
  setIsProcessing 
}) => {
  const handleToggleAdmin = async (memberId: string, currentRole: 'admin' | 'member') => {
    if (isProcessing) return;
    const newRole = currentRole === 'admin' ? 'member' : 'admin';
    try {
      setIsProcessing(true);
      await APIService.getInstance().makeRequest(`/api/teams/${team.team_id}/members/${memberId}`, {
        method: 'PUT',
        body: JSON.stringify({ role: newRole }),
      });
      await refreshTeams();
    } catch (err) {
      console.error('Error updating member role:', err);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleRemoveMember = async (memberId: string) => {
    if (!confirm('Are you sure you want to remove this member?') || isProcessing) return;
    try {
      setIsProcessing(true);
      await APIService.getInstance().makeRequest(`/api/teams/${team.team_id}/members/${memberId}`, {
        method: 'DELETE'
      });
      await refreshTeams();
    } catch (err) {
      console.error('Error removing member:', err);
    } finally {
      setIsProcessing(false);
    }
  };

  if (team.members.length === 0) {
    return (
      <div className="p-8 text-center text-gray-400 bg-gray-800/50 rounded-lg border border-gray-700">
        No team members found
      </div>
    );
  }

  return (
    <div className="mt-8">
      <h2 className="text-xl font-semibold text-gray-200 mb-4">Team Members</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
        {team.members.map((member) => (
          <div key={member.user_id} className="bg-gray-800 rounded-lg border border-gray-700">
            <div className="p-4 flex items-start gap-3">
              {/* Member Icon */}
              <div className={`
                w-10 h-10 flex items-center justify-center rounded-lg
                ${member.role === 'owner' ? 'bg-yellow-900/30 text-yellow-400' :
                  member.role === 'admin' ? 'bg-blue-900/30 text-blue-400' :
                  'bg-gray-700/50 text-gray-400'}
              `}>
                {member.role === 'owner' ? (
                  <Crown size={24} />
                ) : member.role === 'admin' ? (
                  <Shield size={24} />
                ) : (
                  <Users size={24} />
                )}
              </div>

              {/* Member Info */}
              <div className="flex-1">
                <h3 className="text-gray-200 font-medium text-sm">{member.user_name}</h3>
                <p className="text-gray-400 text-xs mt-0.5">{member.user_email}</p>
                <span className={`
                  inline-flex px-2 py-0.5 text-xs font-medium rounded-full mt-2
                  ${member.role === 'owner' ? 'bg-yellow-900/50 text-yellow-200' :
                    member.role === 'admin' ? 'bg-blue-900/50 text-blue-200' :
                    'bg-gray-700 text-gray-300'}
                `}>
                  {member.role.charAt(0).toUpperCase() + member.role.slice(1)}
                </span>
              </div>
            </div>

            {/* Member Actions */}
            {team.permissions.canManageMembers && member.role !== 'owner' && (
              <div className="p-4 pt-0 flex items-center gap-2">
                <button
                  onClick={() => handleToggleAdmin(member.user_id, member.role as 'admin' | 'member')}
                  className="flex-1 px-3 py-1.5 bg-gray-700 text-gray-200 rounded-lg
                           hover:bg-gray-600 transition-colors text-sm
                           disabled:opacity-50 disabled:cursor-not-allowed"
                  disabled={isProcessing}
                >
                  {member.role === 'admin' ? 'Remove Admin' : 'Make Admin'}
                </button>
                <button
                  onClick={() => handleRemoveMember(member.user_id)}
                  className="px-3 py-1.5 bg-red-600 text-white rounded-lg
                           hover:bg-red-700 transition-colors text-sm
                           disabled:opacity-50 disabled:cursor-not-allowed"
                  disabled={isProcessing}
                >
                  Remove
                </button>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TeamMemberList;