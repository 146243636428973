// src/components/VersionFooter.tsx
import React, { useState, useEffect } from 'react';
import { Info } from 'lucide-react';
import { apiService } from '../services/APIService';
import versionData from '../version.json';

interface APIVersionInfo {
  version: string;
  buildDate: string;
  commitHash: string;
  environment: string;
}

export const VersionFooter: React.FC = () => {
  const [showDetails, setShowDetails] = useState(false);
  const [apiVersion, setApiVersion] = useState<APIVersionInfo | null>(null);
  
  useEffect(() => {
    const fetchApiVersion = async () => {
      try {
        const response = await apiService.makeRequest('/api/version');
        const data = await response.json();
        setApiVersion(data);
      } catch (error) {
        console.error('Failed to fetch API version:', error);
      }
    };

    fetchApiVersion();
  }, []);
  
  return (
    <footer className="fixed bottom-0 left-0 right-0 bg-gray-900 text-gray-200 border-t border-gray-800 px-4 py-1.5 z-10">
      <div className="flex justify-between items-center text-sm">
        <div className="flex items-center gap-2">
          <button
            onClick={() => setShowDetails(!showDetails)}
            className="flex items-center gap-1.5 text-gray-400 hover:text-gray-200 transition-colors"
            title="Version Information"
          >
            <Info size={14} className="opacity-75" />
            <span className="text-xs font-medium">
              UI v{versionData.major}.{versionData.minor}.{versionData.patch}-{versionData.buildNumber}
            </span>
          </button>
        </div>
        
        {showDetails && (
          <div className="flex items-center gap-6 text-xs">
            <div className="text-gray-300">
              <span className="text-gray-500 font-medium">UI:</span>{' '}
              v{versionData.major}.{versionData.minor}.{versionData.patch}-{versionData.buildNumber}
              <span className="ml-2 text-gray-500">
                (Built: {new Date(versionData.buildDate).toLocaleDateString()})
              </span>
            </div>
            
            {apiVersion && (
              <div className="text-gray-300">
                <span className="text-gray-500 font-medium">API:</span>{' '}
                {apiVersion.version}
                <span className="ml-2 text-gray-500">
                  ({apiVersion.commitHash})
                </span>
              </div>
            )}
            
            <span className="capitalize text-gray-400 px-2 py-0.5 bg-gray-800 rounded-full text-[10px] font-medium">
              {import.meta.env.MODE}
            </span>
          </div>
        )}
      </div>
    </footer>
  );
};