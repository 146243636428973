import React, { useState, useEffect, useRef } from 'react';
import { APIService } from '../services/APIService';
import { ChatMessage } from '../types/chat';
import { KnowledgeBase } from '../types/knowledgeBase';
import { ImageIcon, Wand2, SendHorizonal, Loader2 } from 'lucide-react';

const AI_MODELS = {
  'Llama-2 7B': '@cf/meta/llama-2-7b-chat-int8',
  'Llama-2 7B FP16': '@cf/meta/llama-2-7b-chat-fp16',
  'Llama-3 8B': '@cf/meta/llama-3-8b-instruct',
  'Llama-3.1 8B': '@cf/meta/llama-3.1-8b-instruct',
  'Mistral 7B': '@cf/mistral/mistral-7b-instruct-v0.1',
  'Mixtral 8x7B': '@cf/mistral/mixtral-8x7b-instruct-v0.1',
  'CodeLlama': '@cf/meta/codellama-7b-instruct',
  'Claude 3': '@cf/anthropic/claude-3-sonnet-20240229',
  'Image Generation': '@cf/black-forest-labs/flux-1-schnell'
} as const;

type ModelKey = keyof typeof AI_MODELS;

interface MessageContent {
  type: 'text' | 'image';
  content: string;
}

interface EnhancedChatMessage extends Omit<ChatMessage, 'content'> {
  content: MessageContent;
}

export const ChatTest: React.FC = () => {
  const [messages, setMessages] = useState<EnhancedChatMessage[]>([]);
  const [inputMessage, setInputMessage] = useState('');
  const [selectedModel, setSelectedModel] = useState<ModelKey>('Llama-2 7B');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [knowledgeBases, setKnowledgeBases] = useState<KnowledgeBase[]>([]);
  const [selectedKbId, setSelectedKbId] = useState<string | null>(null);
  const [loadingKbs, setLoadingKbs] = useState(false);
  const [isImageMode, setIsImageMode] = useState(false);
  
  const chatHistoryRef = useRef<HTMLDivElement>(null);
  const apiService = useRef<APIService | null>(null);
  const abortControllerRef = useRef<AbortController | null>(null);
  const responseTextRef = useRef<string>('');

  useEffect(() => {
    apiService.current = new APIService();
    loadKnowledgeBases();
  }, []);

  useEffect(() => {
    if (chatHistoryRef.current) {
      chatHistoryRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [messages]);

  const loadKnowledgeBases = async () => {
    if (!apiService.current) return;
    setLoadingKbs(true);
    try {
      const response = await apiService.current.makeRequest('/api/knowledgebases');
      if (!response.ok) {
        throw new Error('Failed to load knowledge bases');
      }
      const data = await response.json();
      setKnowledgeBases(data);
    } catch (err) {
      console.error('Error loading knowledge bases:', err);
      setError('Failed to load knowledge bases');
    } finally {
      setLoadingKbs(false);
    }
  };

  const toggleMode = () => {
    setIsImageMode(!isImageMode);
    if (!isImageMode) {
      setSelectedModel('Image Generation');
      setSelectedKbId(null); // Disable KB selection in image mode
    } else {
      setSelectedModel('Llama-2 7B');
    }
  };

  const generateImage = async (prompt: string): Promise<string> => {
    if (!apiService.current) throw new Error('API service not initialized');

    const response = await apiService.current.makeRequest('/api/image-gen', {
      method: 'POST',
      body: JSON.stringify({ prompt })
    });

    if (!response.ok) {
      throw new Error('Failed to generate image');
    }

    return response.text();
  };

  const getProviderFromModel = (model: string): string => {
    if (model.includes('/anthropic/')) return 'anthropic';
    if (model.includes('/mistral/')) return 'mistral';
    return 'meta';
  };

  const updateLastMessage = (content: string, status: 'sending' | 'sent' | 'error' = 'sending') => {
    setMessages(prev => {
      const newMessages = [...prev];
      const lastIndex = newMessages.length - 1;
      if (lastIndex >= 0) {
        newMessages[lastIndex] = {
          ...newMessages[lastIndex],
          content: { type: 'text', content },
          status
        };
      }
      return newMessages;
    });
  };

  const processStreamedResponse = async (response: Response) => {
    const reader = response.body?.getReader();
    if (!reader) return;

    responseTextRef.current = '';
    setMessages(prev => [...prev, { 
      role: 'assistant', 
      content: { type: 'text', content: '' },
      status: 'sending' 
    }]);

    try {
      let buffer = '';
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        const chunk = new TextDecoder().decode(value);
        buffer += chunk;

        const lines = buffer.split('\n');
        buffer = lines.pop() || '';

        for (const line of lines) {
          if (line.trim() === '') continue;
          if (line.startsWith('data: ')) {
            try {
              const data = JSON.parse(line.slice(5));
              if (data.content) {
                responseTextRef.current += data.content;
                updateLastMessage(responseTextRef.current);
              }
            } catch (e) {
              console.error('Error parsing SSE message:', e);
            }
          }
        }
      }

      updateLastMessage(responseTextRef.current, 'sent');

    } catch (error) {
      console.error('Stream processing error:', error);
      if (error instanceof Error && error.name === 'AbortError') {
        updateLastMessage(responseTextRef.current + ' [Interrupted]', 'error');
      } else {
        updateLastMessage('Error: Failed to process response', 'error');
      }
    }
  };

  const sendMessage = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!inputMessage.trim() || isLoading) return;
    
    const messageContent = { type: 'text' as const, content: inputMessage };
    const userMessage: EnhancedChatMessage = {
      role: 'user',
      content: messageContent,
      status: 'sent'
    };

    setMessages(prev => [...prev, userMessage]);
    setInputMessage('');
    setIsLoading(true);
    setError(null);

    try {
      if (!apiService.current) {
        throw new Error('API service not initialized');
      }

      if (isImageMode || selectedModel === 'Image Generation') {
        // Handle image generation
        const imageDataUrl = await generateImage(inputMessage);
        
        setMessages(prev => [...prev, {
          role: 'assistant',
          content: { type: 'image', content: imageDataUrl },
          status: 'sent'
        }]);
      } else {
        // Handle normal chat
        if (abortControllerRef.current) {
          abortControllerRef.current.abort();
        }
        abortControllerRef.current = new AbortController();

        const modelId = AI_MODELS[selectedModel];
        const provider = getProviderFromModel(modelId);
        
        const response = await apiService.current.sendChatMessage(
          inputMessage,
          {
            conversationHistory: messages.map(m => ({
              role: m.role,
              content: m.content.content
            })),
            model: modelId,
            provider: provider,
            kbId: selectedKbId
          },
          abortControllerRef.current.signal
        );

        if (!response.ok) {
          throw new Error(`API Error: ${response.status}`);
        }

        if (!response.body) {
          throw new Error('No response stream available');
        }

        await processStreamedResponse(response);
      }
    } catch (error) {
      console.error('Error:', error);
      setError(error instanceof Error ? error.message : 'Failed to send message');
      setMessages(prev => [...prev, {
        role: 'assistant',
        content: { type: 'text', content: 'An error occurred while processing your message. Please try again.' },
        status: 'error'
      }]);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col h-full max-w-6xl mx-auto p-4 bg-gray-900">
      <div className="mb-4 grid grid-cols-2 gap-4 items-end">
        <div>
          <label className="block text-sm font-medium text-gray-200 mb-1">
            Model
          </label>
          <div className="flex gap-2">
            <select
              value={selectedModel}
              onChange={(e) => {
                const newModel = e.target.value as ModelKey;
                setSelectedModel(newModel);
                setIsImageMode(newModel === 'Image Generation');
              }}
              className="flex-1 p-3 bg-gray-800 text-gray-200 rounded-lg border border-gray-700
                       focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            >
              {Object.keys(AI_MODELS).map((model) => (
                <option key={model} value={model}>
                  {model}
                </option>
              ))}
            </select>
            <button
              onClick={toggleMode}
              className={`p-3 rounded-lg border transition-colors ${
                isImageMode 
                  ? 'bg-purple-600 text-white border-purple-500 hover:bg-purple-700'
                  : 'bg-gray-800 text-gray-200 border-gray-700 hover:bg-gray-700'
              }`}
              title={isImageMode ? 'Switch to chat mode' : 'Switch to image generation mode'}
            >
              {isImageMode ? <Wand2 size={20} /> : <ImageIcon size={20} />}
            </button>
          </div>
        </div>

        {!isImageMode && (
          <div>
            <label className="block text-sm font-medium text-gray-200 mb-1">
              Knowledge Base {loadingKbs && '(Loading...)'}
            </label>
            <select
              value={selectedKbId || ''}
              onChange={(e) => setSelectedKbId(e.target.value || null)}
              disabled={loadingKbs || isImageMode}
              className="w-full p-3 bg-gray-800 text-gray-200 rounded-lg border border-gray-700
                       focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent
                       disabled:opacity-50"
            >
              <option value="">None (General chat)</option>
              {knowledgeBases.map((kb) => (
                <option key={kb.kb_id} value={kb.kb_id}>
                  {kb.title}
                </option>
              ))}
            </select>
          </div>
        )}
      </div>

      {error && (
        <div className="mb-4 p-4 bg-red-900/50 text-red-200 rounded-lg border border-red-800
                     flex justify-between items-center">
          <span>{error}</span>
          <button
            onClick={() => setError(null)}
            className="p-1 hover:bg-red-800/50 rounded-lg transition-colors"
          >
            ×
          </button>
        </div>
      )}

      <div className="flex-1 overflow-y-auto mb-4 p-4 bg-gray-800 rounded-lg border border-gray-700">
        {selectedKbId && !isImageMode && (
          <div className="mb-4 p-2 bg-blue-900/30 text-blue-200 rounded-lg border border-blue-800/50 text-sm">
            Using knowledge base: {knowledgeBases.find(kb => kb.kb_id.toString() === selectedKbId)?.title}
          </div>
        )}
        
        {messages.map((message, index) => (
          <div
            key={index}
            className={`mb-4 p-4 rounded-lg ${
              message.role === 'user'
                ? 'bg-blue-600 text-white ml-auto max-w-[80%]'
                : 'bg-gray-700 text-gray-200 mr-auto max-w-[80%]'
            }`}
          >
            <div className="font-semibold mb-1 text-sm opacity-75">
              {message.role === 'user' ? 'You' : 'Assistant'}:
            </div>
            {message.content.type === 'image' ? (
         
              <img 
      src={message.content.content} 
      alt="AI Generated"
      className="max-w-[500px] w-full h-auto rounded-lg border border-gray-600 
                shadow-lg hover:shadow-xl transition-shadow"
      loading="lazy"
    />
            ) : (
              <div className="whitespace-pre-wrap break-words">
                {message.content.content}
              </div>
            )}
          </div>
        ))}
        <div ref={chatHistoryRef} />
        
        {isLoading && (
          <div className="bg-gray-700 text-gray-200 p-4 rounded-lg mr-auto max-w-[80%]">
            <div className="flex gap-1">
              <span className="w-2 h-2 bg-gray-400 rounded-full animate-bounce"></span>
              <span className="w-2 h-2 bg-gray-400 rounded-full animate-bounce delay-100"></span>
              <span className="w-2 h-2 bg-gray-400 rounded-full animate-bounce delay-200"></span>
            </div>
          </div>
        )}
      </div>

      <form onSubmit={sendMessage} className="flex gap-2 bg-gray-800 p-4 rounded-lg border border-gray-700">
        <textarea
          value={inputMessage}
          onChange={(e) => setInputMessage(e.target.value)}
          placeholder={
            isImageMode 
              ? "Describe the image you want to generate..."
              : `Type your message...${selectedKbId ? ' (Using knowledge base)' : ''}`
          }
          disabled={isLoading}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              if (inputMessage.trim()) {
                sendMessage(e);
              }
            }
          }}
          className="flex-1 min-h-[40px] max-h-[120px] p-3 bg-gray-700 text-gray-200 rounded-lg 
          border border-gray-600 resize-none placeholder-gray-400
          focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent
          disabled:opacity-50"
/>
<button
 type="submit"
 disabled={isLoading || !inputMessage.trim()}
 className="px-6 bg-blue-600 text-white rounded-lg hover:bg-blue-700 
          transition-colors disabled:opacity-50 disabled:cursor-not-allowed
          flex items-center justify-center"
>
 {isLoading ? (
   <Loader2 size={20} className="animate-spin" />
 ) : (
   <SendHorizonal size={20} />
 )}
</button>
</form>
</div>
);
};