import React, { useState } from 'react';
import { Lock, Users, Trash2, Plus } from 'lucide-react';
import { DocumentCreationModal } from './DocumentCreationModal';
import type { Document } from '../types/document';

type DocumentType = NonNullable<Document['document_type']>;

interface DocumentsPanelProps {
  selectedKb: number | null;
  documents: Document[];
  loading: boolean;
  error: string | null;
  onRetry: () => void;
  onSelectDocument: (doc: Document) => void;
  onDeleteDocument: (docId: number) => Promise<void>;
  isProcessing: boolean;
  createDocument: (data: {
    title: string;
    content: string;
    document_type: DocumentType;
  }) => Promise<void>;
  refreshDocuments: () => Promise<void>;
}

export const DocumentsPanel: React.FC<DocumentsPanelProps> = ({
  selectedKb,
  documents,
  loading,
  error,
  onRetry,
  onSelectDocument,
  onDeleteDocument,
  isProcessing,
  createDocument,
  refreshDocuments
}) => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [deletingDocId, setDeletingDocId] = useState<number | null>(null);

  const handleCreateDocument = async (data: {
    title: string;
    content: string;
    document_type: DocumentType;
  }) => {
    if (!selectedKb) return;

    try {
      await createDocument(data);
      setShowCreateModal(false);
      await refreshDocuments();
    } catch (error) {
      console.error('Failed to create document:', error);
    }
  };

  const handleDeleteDocument = async (docId: number) => {
    if (!window.confirm('Are you sure you want to delete this document?')) return;
    
    try {
      setDeletingDocId(docId);
      await onDeleteDocument(docId);
      await refreshDocuments();
    } catch (error) {
      console.error('Failed to delete document:', error);
    } finally {
      setDeletingDocId(null);
    }
  };

  return (
    <div className="flex flex-col h-full bg-gray-800 rounded-lg border border-gray-700 p-4">
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-xl font-semibold text-gray-200">Documents</h2>
        {selectedKb && (
          <button 
            onClick={() => setShowCreateModal(true)}
            className="flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg 
                     hover:bg-blue-700 transition-colors"
          >
            <Plus size={16} />
            <span>New Document</span>
          </button>
        )}
      </div>

      {selectedKb ? (
        <>
          {error && (
            <div className="mb-4 p-3 bg-red-900/50 border border-red-800 text-red-200 rounded-lg 
                          flex justify-between items-center">
              <span>{error}</span>
              <button 
                onClick={onRetry}
                className="px-3 py-1 bg-red-800 hover:bg-red-700 text-red-100 rounded-md 
                         transition-colors"
              >
                Retry
              </button>
            </div>
          )}

          <div className="flex-1 overflow-y-auto border border-gray-700 rounded-lg bg-gray-900">
            {loading ? (
              <div className="flex items-center justify-center h-full text-gray-400">
                <div className="w-5 h-5 border-2 border-gray-400 border-t-transparent rounded-full animate-spin mr-2" />
                <span>Loading documents...</span>
              </div>
            ) : documents.length === 0 ? (
              <div className="flex items-center justify-center h-full text-gray-400 p-4 text-center">
                No documents found. Click "New Document" to get started.
              </div>
            ) : (
              <div className="divide-y divide-gray-700">
                {documents.map((doc) => (
                  <div
                    key={doc.doc_id}
                    className="group flex items-center justify-between p-3 hover:bg-gray-700 transition-colors"
                  >
                    <div
                      className="flex items-center gap-3 flex-1 min-w-0 cursor-pointer"
                      onClick={() => onSelectDocument(doc)}
                    >
                      <span className="text-gray-200 font-medium truncate">
                        {doc.title}
                      </span>
                      <div className="flex items-center gap-2 text-xs text-gray-400">
                        <span>{doc.document_type.toUpperCase()}</span>
                        {doc.knowledgeBase?.visibility === 'team' ? (
                          <Users size={16} className="text-blue-400" />
                        ) : (
                          <Lock size={16} className="text-gray-400" />
                        )}
                      </div>
                    </div>
                    {doc.permissions?.canDelete && (
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteDocument(doc.doc_id);
                        }}
                        className="p-1.5 text-gray-400 opacity-0 group-hover:opacity-100
                                 hover:text-red-400 hover:bg-red-900/20 rounded-md transition-all
                                 disabled:opacity-50 disabled:cursor-not-allowed"
                        disabled={deletingDocId === doc.doc_id || loading}
                      >
                        <Trash2 
                          size={16} 
                          className={deletingDocId === doc.doc_id ? 'animate-spin' : ''} 
                        />
                      </button>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="flex items-center justify-center h-full text-gray-400 p-4 text-center">
          Select a knowledge base to view its documents
        </div>
      )}

      {showCreateModal && (
        <DocumentCreationModal
          onClose={() => setShowCreateModal(false)}
          onCreateDocument={handleCreateDocument}
          isProcessing={isProcessing}
        />
      )}
    </div>
  );
};