// src/config/environment.ts
export type FirebaseConfig = {
    apiKey: string;
    authDomain: string;
    projectId: string;
    storageBucket?: string;
  };
  
  export type EnvironmentConfig = {
    apiUrl: string;
    firebase: FirebaseConfig;
    environment: string;
  };
  
  const environments = {
    development: {
      apiUrl: import.meta.env.DEV 
        ? 'http://localhost:8787'
        : 'https://apidev.teamspress.com',
      firebase: {
        apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
        authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
        projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
        storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET
      },
      environment: 'development'
    },
    staging: {
      apiUrl: 'https://apistaging.teamspress.com',
      firebase: {
        apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
        authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
        projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
        storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET
      },
      environment: 'staging'
    },
    production: {
      apiUrl: 'https://api.teamspress.com',
      firebase: {
        apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
        authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
        projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
        storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET
      },
      environment: 'production'
    }
  } as const;
  
  export function getCurrentEnvironment(): 'development' | 'staging' | 'production' {
    return (import.meta.env.MODE as 'development' | 'staging' | 'production') || 'development';
  }
  
  export function isEnvironmentLoaded(): boolean {
    const env = environments[getCurrentEnvironment()];
    return Boolean(
      env &&
      env.firebase.apiKey &&
      env.firebase.authDomain &&
      env.firebase.projectId
    );
  }
  
  export const environment = environments[getCurrentEnvironment()];