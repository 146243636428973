// src/hooks/useDocuments.ts
import { useState, useEffect, useCallback, useRef } from 'react';
import { apiService } from '../services/APIService';
import type { Document } from '../types/document';
import { APIError } from '../types/api';

interface CreateDocumentData {
  kb_id: number;
  title: string;
  content: string;
  document_type: NonNullable<Document['document_type']>; // Make document_type required
}

export function useDocuments(kbId: number | null) {
  const [documents, setDocuments] = useState<Document[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const abortControllerRef = useRef<AbortController | null>(null);

  const loadDocuments = useCallback(async (silent = false) => {
    if (!kbId) return;

    // Cleanup previous request if any
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    // Create new abort controller
    abortControllerRef.current = new AbortController();
    const signal = abortControllerRef.current.signal;

    if (!silent) setLoading(true);
    setError(null);

    try {
      const response = await apiService.getDocuments(kbId);
      if (!signal.aborted) {
        setDocuments(response);
      }
    } catch (error) {
      if (error instanceof Error && error.name === 'AbortError') {
        return; // Ignore aborted requests
      }
      setError('Failed to load documents');
      console.error('Error loading documents:', error);
    } finally {
      if (!silent) setLoading(false);
      if (abortControllerRef.current?.signal === signal) {
        abortControllerRef.current = null;
      }
    }
  }, [kbId]);

  const createDocument = useCallback(async (data: CreateDocumentData) => {
    try {
      const response = await apiService.createDocument(data);
      // Silent reload after creation
      await loadDocuments(true);
      return response;
    } catch (error) {
      console.error('Error creating document:', error);
      if (error instanceof APIError) {
        throw error;
      }
      throw new APIError(
        error instanceof Error ? error.message : 'Failed to create document'
      );
    }
  }, [loadDocuments]);

  const deleteDocument = useCallback(async (docId: number) => {
    try {
      await apiService.deleteDocument(docId);
      // Silent reload after deletion
      await loadDocuments(true);
    } catch (error) {
      console.error('Error deleting document:', error);
      if (error instanceof APIError) {
        throw error;
      }
      throw new APIError(
        error instanceof Error ? error.message : 'Failed to delete document'
      );
    }
  }, [loadDocuments]);

  const updateDocument = useCallback(async (docId: number, data: Partial<CreateDocumentData>) => {
    try {
      const response = await apiService.updateDocument(docId, data);
      // Silent reload after update
      await loadDocuments(true);
      return response;
    } catch (error) {
      console.error('Error updating document:', error);
      if (error instanceof APIError) {
        throw error;
      }
      throw new APIError(
        error instanceof Error ? error.message : 'Failed to update document'
      );
    }
  }, [loadDocuments]);

  useEffect(() => {
    if (kbId) {
      loadDocuments();
    }

    return () => {
      // Cleanup on unmount or when kbId changes
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [kbId, loadDocuments]);

  return {
    documents,
    loading,
    error,
    createDocument,
    deleteDocument,
    updateDocument,
    loadDocuments: () => loadDocuments(false),
    refresh: () => loadDocuments(true)
  };
}

// Export types for use in components
export type UseDocumentsReturn = ReturnType<typeof useDocuments>;