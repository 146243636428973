import React, { useState, useCallback } from 'react';
import { KnowledgeBasePanel } from './KnowledgeBasePanel';
import { DocumentsPanel } from './DocumentsPanel';
import DocumentViewer from './DocumentViewer';
import { useDocuments } from '../hooks/useDocuments';
import { useKnowledgeBases } from '../hooks/useKnowledgeBases';
import type { Document } from '../types/document';
import type { 
  KnowledgeBase, 
  CreateKnowledgeBaseRequest, 
  KnowledgeBaseTestProps 
} from '../types/knowledgeBase';

type DocumentType = NonNullable<Document['document_type']>;



export const KnowledgeBaseTest: React.FC<KnowledgeBaseTestProps> = ({ userContext }) => {
  const [title, setTitle] = useState('');
  const [visibility, setVisibility] = useState<'private' | 'team'>('private');
  const [isCreating, setIsCreating] = useState(false);
  const [selectedKb, setSelectedKb] = useState<number | null>(null);
  const [selectedDoc, setSelectedDoc] = useState<Document | null>(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const {
    knowledgeBases,
    loading: kbLoading,
    error: kbError,
    createKnowledgeBase,
    deleteKnowledgeBase,
    loadKnowledgeBases
  } = useKnowledgeBases();

  const {
    documents,
    loading: docLoading,
    error: docError,
    createDocument,
    deleteDocument,
    loadDocuments
  } = useDocuments(selectedKb);

  const handleCreateKb = useCallback(async () => {
    if (!title.trim() || isCreating) return;

    setIsCreating(true);
    try {
      const request: CreateKnowledgeBaseRequest = {
        title,
        visibility,
        team_id: visibility === 'team' ? userContext?.teams?.[0]?.team_id : undefined
      };

      await createKnowledgeBase(request);
      setTitle('');
      setVisibility('private');
      await loadKnowledgeBases();
    } catch (error) {
      console.error('Failed to create knowledge base:', error);
    } finally {
      setIsCreating(false);
    }
  }, [title, visibility, isCreating, createKnowledgeBase, loadKnowledgeBases, userContext]);

  const handleCreateDocument = async (data: { 
    title: string; 
    content: string; 
    document_type: DocumentType;
  }) => {
    if (!selectedKb) return;
    
    setIsProcessing(true);
    try {
      await createDocument({
        ...data,
        kb_id: selectedKb
      });
      await loadDocuments();
    } catch (error) {
      console.error('Failed to create document:', error);
      throw error;
    } finally {
      setIsProcessing(false);
    }
  };

  const handleDeleteDocument = async (docId: number) => {
    try {
      if (selectedDoc?.doc_id === docId) {
        setSelectedDoc(null);
      }
      await deleteDocument(docId);
      await loadDocuments();
    } catch (error) {
      console.error('Failed to delete document:', error);
    }
  };

  const handleDeleteKb = async (kb: KnowledgeBase) => {
    if (!window.confirm(`Are you sure you want to delete "${kb.title}"?`)) return;
    
    try {
      await deleteKnowledgeBase(kb.kb_id);
      if (selectedKb === kb.kb_id) {
        setSelectedKb(null);
        setSelectedDoc(null);
      }
      await loadKnowledgeBases();
    } catch (error) {
      console.error('Failed to delete knowledge base:', error);
    }
  };

  return (
    <div className="kb-container">
      <div className="kb-split-panel">
        <KnowledgeBasePanel
          title={title}
          setTitle={setTitle}
          visibility={visibility}
          setVisibility={setVisibility}
          isCreating={isCreating}
          knowledgeBases={knowledgeBases}
          selectedKb={selectedKb}
          onSelectKb={setSelectedKb}
          onCreateKb={handleCreateKb}
          onDeleteKb={handleDeleteKb}
          loading={kbLoading}
          error={kbError}
          onRetry={loadKnowledgeBases}
          hasTeamAccess={userContext?.teams && userContext.teams.length > 0}
        />

        <DocumentsPanel
          selectedKb={selectedKb}
          documents={documents}
          loading={docLoading}
          error={docError}
          onRetry={loadDocuments}
          onSelectDocument={setSelectedDoc}
          onDeleteDocument={handleDeleteDocument}
          isProcessing={isProcessing}
          createDocument={handleCreateDocument}
          refreshDocuments={loadDocuments}
        />
      </div>

      {selectedDoc && (
        <DocumentViewer
          document={selectedDoc}
          onClose={() => setSelectedDoc(null)}
        />
      )}
    </div>
  );
};

export default KnowledgeBaseTest;