// src/components/TeamManagement.tsx
import { useState, useEffect } from 'react';
import { APIService } from '../services/APIService';
import { TeamList } from './TeamList'; // Change to named import
import { TeamMemberList } from './TeamMemberList'; // Change to named import
import type { TeamResponse } from '../types/team';

export const TeamManagement = () => {
  const [teams, setTeams] = useState<TeamResponse[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [selectedTeamId, setSelectedTeamId] = useState<number | null>(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const selectedTeam = teams.find(team => team.team_id === selectedTeamId);

  useEffect(() => {
    const fetchTeams = async () => {
      setIsProcessing(true);
      try {
        const response = await APIService.getInstance().makeRequest('/api/teams');
        if (!response.ok) throw new Error('Failed to load teams');
        const data: TeamResponse[] = await response.json();
        setTeams(data);
        if (data.length > 0) setSelectedTeamId(data[0].team_id);
      } catch (err) {
        console.error('Error loading teams:', err);
        setError('Failed to load teams');
      } finally {
        setIsProcessing(false);
      }
    };
    fetchTeams();
  }, []);

  const refreshTeams = async () => {
    try {
      setError(null);
      const response = await APIService.getInstance().makeRequest('/api/teams');
      if (!response.ok) throw new Error('Failed to refresh teams');
      const data: TeamResponse[] = await response.json();
      setTeams(data);
    } catch (err) {
      console.error('Error refreshing teams:', err);
      setError('Failed to refresh teams');
    }
  };

  return (
    <div className="max-w-7xl mx-auto p-4">
      {error && (
        <div className="mb-6 p-4 bg-red-900/50 border border-red-800 text-red-200 rounded-lg">
          {error}
        </div>
      )}
      
      <TeamList
        teams={teams}
        selectedTeamId={selectedTeamId}
        setSelectedTeamId={setSelectedTeamId}
        refreshTeams={refreshTeams}
        isProcessing={isProcessing}
        setIsProcessing={setIsProcessing}
      />
      
      {selectedTeam && (
        <TeamMemberList
          team={selectedTeam}
          refreshTeams={refreshTeams}
          isProcessing={isProcessing}
          setIsProcessing={setIsProcessing}
        />
      )}
    </div>
  );
};

export default TeamManagement;