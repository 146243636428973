// src/components/TeamList.tsx (beginning part)
import React, { useState } from 'react';
import { Plus, X, ChevronDown } from 'lucide-react';
import { APIService } from '../services/APIService';
import type { TeamResponse } from '../types/team';

interface TeamListProps {
  teams: TeamResponse[];
  selectedTeamId: number | null;
  setSelectedTeamId: React.Dispatch<React.SetStateAction<number | null>>;
  refreshTeams: () => void;
  isProcessing: boolean;
  setIsProcessing: React.Dispatch<React.SetStateAction<boolean>>;
}

export const TeamList: React.FC<TeamListProps> = ({
  teams,
  selectedTeamId,
  setSelectedTeamId,
  refreshTeams,
  isProcessing,
  setIsProcessing,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [newTeamName, setNewTeamName] = useState('');
  const [newTeamDescription, setNewTeamDescription] = useState('');
  const [newMemberEmail, setNewMemberEmail] = useState('');
  const [newMemberRole, setNewMemberRole] = useState<'member' | 'admin'>('member');

  const handleCreateTeam = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!newTeamName.trim() || isProcessing) return;

    try {
      setIsProcessing(true);
      await APIService.getInstance().makeRequest('/api/teams', {
        method: 'POST',
        body: JSON.stringify({
          name: newTeamName.trim(),
          description: newTeamDescription.trim() || null,
        }),
      });
      await refreshTeams();
      setNewTeamName('');
      setNewTeamDescription('');
      setShowModal(false);
    } catch (err) {
      console.error('Error creating team:', err);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleAddMember = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!selectedTeamId || !newMemberEmail || isProcessing) return;

    try {
      setIsProcessing(true);
      await APIService.getInstance().makeRequest(`/api/teams/${selectedTeamId}/members`, {
        method: 'POST',
        body: JSON.stringify({
          email: newMemberEmail,
          role: newMemberRole,
        }),
      });
      await refreshTeams();
      setNewMemberEmail('');
      setNewMemberRole('member');
    } catch (err) {
      console.error('Error adding member:', err);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="mb-8">
      {/* Control Bar */}
      <div className="flex flex-wrap items-center justify-between gap-4 p-4 bg-gray-800 rounded-lg">
        {/* Team Selection */}
        <div className="flex items-center gap-4 min-w-[300px]">
          <div className="relative flex-1">
            <select
              value={selectedTeamId || ''}
              onChange={(e) => setSelectedTeamId(Number(e.target.value))}
              disabled={isProcessing}
              className="w-full h-10 pl-4 pr-10 text-gray-200 bg-gray-700/80 rounded-lg border border-gray-600 
                       focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent 
                       appearance-none cursor-pointer"
            >
              <option value="">Select Team</option>
              {teams.map((team) => (
                <option key={team.team_id} value={team.team_id}>
                  {team.name}
                </option>
              ))}
            </select>
            <ChevronDown className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 pointer-events-none" size={20} />
          </div>

          <button
            onClick={() => setShowModal(true)}
            disabled={isProcessing}
            className="flex items-center gap-2 px-4 h-10 bg-blue-600 text-white rounded-lg 
                     hover:bg-blue-700 transition-colors disabled:opacity-50"
          >
            <Plus size={20} />
            Create Team
          </button>
        </div>

        {/* Member Addition */}
        {selectedTeamId && (
          <div className="flex items-center gap-4 min-w-[300px]">
            <input
              type="email"
              value={newMemberEmail}
              onChange={(e) => setNewMemberEmail(e.target.value)}
              placeholder="Enter member email"
              className="flex-1 h-10 px-4 text-gray-200 bg-gray-700/80 rounded-lg border border-gray-600 
                       focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
            <div className="relative w-32">
              <select
                value={newMemberRole}
                onChange={(e) => setNewMemberRole(e.target.value as 'member' | 'admin')}
                className="w-full h-10 pl-4 pr-10 text-gray-200 bg-gray-700/80 rounded-lg border border-gray-600 
                         focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent 
                         appearance-none cursor-pointer"
              >
                <option value="member">Member</option>
                <option value="admin">Admin</option>
              </select>
              <ChevronDown className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 pointer-events-none" size={20} />
            </div>
            <button
              onClick={handleAddMember}
              disabled={isProcessing}
              className="px-4 h-10 bg-blue-600 text-white rounded-lg hover:bg-blue-700 
                       transition-colors disabled:opacity-50"
            >
              Add Member
            </button>
          </div>
        )}
      </div>

      {/* Create Team Modal */}
      {showModal && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
          <div className="w-full max-w-md bg-gray-800 rounded-lg shadow-xl">
            <div className="flex items-center justify-between p-6 border-b border-gray-700">
              <h2 className="text-xl font-semibold text-gray-200">Create New Team</h2>
              <button
                onClick={() => setShowModal(false)}
                className="text-gray-400 hover:text-gray-200 transition-colors"
              >
                <X size={24} />
              </button>
            </div>
            
            <form onSubmit={handleCreateTeam} className="p-6 space-y-6">
              <div>
                <label className="block text-sm font-medium text-gray-200 mb-2">
                  Team Name
                </label>
                <input
                  type="text"
                  value={newTeamName}
                  onChange={(e) => setNewTeamName(e.target.value)}
                  className="w-full px-4 py-2 bg-gray-700 text-gray-200 rounded-lg border border-gray-600 
                           focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  placeholder="Enter team name"
                  required
                />
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-200 mb-2">
                  Description (Optional)
                </label>
                <textarea
                  value={newTeamDescription}
                  onChange={(e) => setNewTeamDescription(e.target.value)}
                  className="w-full px-4 py-2 bg-gray-700 text-gray-200 rounded-lg border border-gray-600 
                           focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent resize-none"
                  rows={3}
                  placeholder="Enter team description"
                />
              </div>

              <div className="flex justify-end gap-3 pt-6 border-t border-gray-700">
                <button
                  type="button"
                  onClick={() => setShowModal(false)}
                  className="px-4 py-2 bg-gray-700 text-gray-200 rounded-lg 
                           hover:bg-gray-600 transition-colors"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={isProcessing || !newTeamName.trim()}
                  className="px-4 py-2 bg-blue-600 text-white rounded-lg 
                           hover:bg-blue-700 transition-colors disabled:opacity-50"
                >
                  {isProcessing ? 'Creating...' : 'Create Team'}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};


export default TeamList;