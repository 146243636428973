import React, { useState } from 'react';
import { X, Maximize2, Minimize2, Copy } from 'lucide-react';

interface DocumentViewerProps {
  document: {
    title: string;
    content: string;
    document_type: string;
  } | null;
  onClose: () => void;
}

const DocumentViewer: React.FC<DocumentViewerProps> = ({ document, onClose }) => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);
  const [viewMode, setViewMode] = useState<'rendered' | 'source'>('rendered');

  if (!document) return null;

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(document.content);
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000);
    } catch (err) {
      console.error('Failed to copy text:', err);
    }
  };

  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };

  const renderContent = () => {
    if (!document.content) {
      return <div>No content available</div>;
    }

    if (viewMode === 'source') {
      return (
        <pre className="whitespace-pre-wrap font-mono text-sm bg-gray-900 text-gray-200 
                     p-6 rounded-lg border border-gray-700 min-h-full">
          {document.content}
        </pre>
      );
    }

    return (
      <div 
        className="prose prose-invert max-w-none px-6"
        dangerouslySetInnerHTML={{ __html: document.content }}
      />
    );
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
      <div
        className={`
          bg-gray-800 shadow-xl rounded-lg overflow-hidden max-w-6xl w-full
          transition-all duration-300 ease-in-out relative
          ${isFullscreen ? 'h-[calc(100vh-2rem)]' : 'h-[calc(100vh-8rem)]'}
        `}
      >
        {/* Header */}
        <div className="flex items-center justify-between p-4 border-b border-gray-700 bg-gray-900">
          <div>
            <h3 className="text-lg font-semibold text-gray-200">{document.title}</h3>
            <p className="text-sm text-gray-400">
              Type: {document.document_type.toUpperCase()}
            </p>
          </div>
          <div className="flex items-center gap-2">
            {/* View Mode Toggle */}
            <div className="flex items-center bg-gray-700 rounded-lg p-1 mr-4">
              <button
                onClick={() => setViewMode('rendered')}
                className={`px-3 py-1 rounded-md text-sm transition-colors ${
                  viewMode === 'rendered'
                    ? 'bg-blue-600 text-white'
                    : 'text-gray-300 hover:text-white'
                }`}
              >
                Rendered
              </button>
              <button
                onClick={() => setViewMode('source')}
                className={`px-3 py-1 rounded-md text-sm transition-colors ${
                  viewMode === 'source'
                    ? 'bg-blue-600 text-white'
                    : 'text-gray-300 hover:text-white'
                }`}
              >
                Source
              </button>
            </div>

            <button
              onClick={handleCopy}
              className="p-2 text-gray-400 hover:text-gray-200 rounded-lg 
                       hover:bg-gray-700/50 transition-colors relative group"
              title="Copy content"
            >
              <Copy size={20} />
              {copySuccess && (
                <span className="absolute -top-10 left-1/2 -translate-x-1/2 px-2 py-1 
                               bg-gray-900 text-gray-200 text-sm rounded shadow-lg
                               border border-gray-700 whitespace-nowrap">
                  Copied!
                </span>
              )}
            </button>
            <button
              onClick={toggleFullscreen}
              className="p-2 text-gray-400 hover:text-gray-200 rounded-lg 
                       hover:bg-gray-700/50 transition-colors"
              title={isFullscreen ? 'Exit fullscreen' : 'Enter fullscreen'}
            >
              {isFullscreen ? <Minimize2 size={20} /> : <Maximize2 size={20} />}
            </button>
            <button
              onClick={onClose}
              className="p-2 text-gray-400 hover:text-gray-200 rounded-lg 
                       hover:bg-gray-700/50 transition-colors"
              title="Close"
            >
              <X size={20} />
            </button>
          </div>
        </div>

        {/* Content */}
        <div className="overflow-auto h-[calc(100%-4rem)] bg-gray-800">
          {renderContent()}
        </div>
      </div>
    </div>
  );
};

export default DocumentViewer;