// src/hooks/useKnowledgeBases.ts
import { useState, useEffect, useRef, useCallback } from 'react';
import { apiService } from '../services/APIService';
import  { KnowledgeBase, CreateKnowledgeBaseRequest } from '../types';

export function useKnowledgeBases() {
  const [knowledgeBases, setKnowledgeBases] = useState<KnowledgeBase[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const loadingRef = useRef(false);
  const abortControllerRef = useRef<AbortController | null>(null);
  const mounted = useRef(true);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, []);

  const loadKnowledgeBases = useCallback(async (silent = false) => {
    // Prevent concurrent loads
    if (loadingRef.current) return;

    // Cleanup previous request if any
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    loadingRef.current = true;
    if (!silent && mounted.current) setLoading(true);
    setError(null);

    // Create new abort controller
    abortControllerRef.current = new AbortController();

    try {
      const result = await apiService.getKnowledgeBases();
      
      if (mounted.current) {
        setKnowledgeBases(result);
        setError(null);
      }
    } catch (error) {
      console.error('Error loading knowledge bases:', error);
      if (error instanceof Error && error.name === 'AbortError') {
        return; // Ignore aborted requests
      }
      if (mounted.current) {
        setError('Failed to load knowledge bases');
      }
    } finally {
      loadingRef.current = false;
      if (!silent && mounted.current) setLoading(false);
      if (abortControllerRef.current) {
        abortControllerRef.current = null;
      }
    }
  }, []);

  const createKnowledgeBase = useCallback(async (request: CreateKnowledgeBaseRequest) => {
    if (!mounted.current) return;
    
    setError(null);
    try {
      const response = await apiService.createKnowledgeBase(request);
      await loadKnowledgeBases(true);
      return response;
    } catch (error) {
      const message = error instanceof Error ? error.message : 'Failed to create knowledge base';
      setError(message);
      throw error;
    }
  }, [loadKnowledgeBases]);

  const deleteKnowledgeBase = useCallback(async (kbId: number) => {
    if (!mounted.current) return;
    
    setError(null);
    try {
      await apiService.deleteKnowledgeBase(kbId);
      await loadKnowledgeBases(true);
    } catch (error) {
      const message = error instanceof Error ? error.message : 'Failed to delete knowledge base';
      setError(message);
    }
  }, [loadKnowledgeBases]);

  useEffect(() => {
    loadKnowledgeBases();
  }, [loadKnowledgeBases]);

  return {
    knowledgeBases,
    loading,
    error,
    createKnowledgeBase,
    deleteKnowledgeBase,
    loadKnowledgeBases: () => loadKnowledgeBases(false),
    refresh: () => loadKnowledgeBases(true)
  };
}