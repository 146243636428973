import React, { useState } from 'react';
import { X, Upload, FileIcon, FileTextIcon, FileType, AlertCircle } from 'lucide-react';
import { PDFExtractor } from '../extractors/PDFExtractor';
import * as mammoth from 'mammoth/mammoth.browser';

type DocumentType = 'text' | 'docx' | 'pdf';

interface DocumentCreationModalProps {
  onClose: () => void;
  onCreateDocument: (data: { 
    title: string; 
    content: string; 
    document_type: DocumentType;
  }) => Promise<void>;
  isProcessing: boolean;
}

export const DocumentCreationModal: React.FC<DocumentCreationModalProps> = ({
  onClose,
  onCreateDocument,
  isProcessing
}) => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [documentType, setDocumentType] = useState<DocumentType>('text');
  const [file, setFile] = useState<File | null>(null);
  const [extractionStatus, setExtractionStatus] = useState<{
    isProcessing: boolean;
    progress: string;
    error?: string;
  }>({
    isProcessing: false,
    progress: ''
  });

  const validateFile = (file: File): boolean => {
    const maxSize = 10 * 1024 * 1024; // 10MB
    if (file.size > maxSize) {
      setExtractionStatus(prev => ({
        ...prev,
        error: 'File size exceeds 10MB limit'
      }));
      return false;
    }
    return true;
  };

  const processDocxFile = async (file: File): Promise<string> => {
    try {
      const arrayBuffer = await file.arrayBuffer();
      console.log('Processing DOCX file:', file.name, 'size:', arrayBuffer.byteLength);

      const result = await mammoth.convertToHtml({ arrayBuffer });
      console.log('Mammoth conversion result:', result);

      if (!result.value) {
        throw new Error('No content extracted from DOCX');
      }

      return result.value;
    } catch (error) {
      console.error('DOCX processing error:', error);
      throw error;
    }
  };

  const handleFileSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (!selectedFile) return;

    console.log('File selected:', selectedFile.name, selectedFile.type);

    // Reset states
    setExtractionStatus({
      isProcessing: true,
      progress: 'Processing file...'
    });
    setContent('');

    // Validate file
    if (!validateFile(selectedFile)) {
      return;
    }

    // Set document type and file
    const extension = selectedFile.name.split('.').pop()?.toLowerCase();
    switch (extension) {
      case 'docx':
        setDocumentType('docx');
        break;
      case 'pdf':
        setDocumentType('pdf');
        break;
      default:
        setDocumentType('text');
    }

    if (!title) {
      setTitle(selectedFile.name.split('.')[0]);
    }

    setFile(selectedFile);

    try {
      if (selectedFile.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || 
          selectedFile.name.toLowerCase().endsWith('.docx')) {
        setExtractionStatus(prev => ({
          ...prev,
          progress: 'Processing DOCX file...'
        }));
        const docxContent = await processDocxFile(selectedFile);
        setContent(docxContent);

      } else if (selectedFile.type === 'application/pdf' || selectedFile.name.toLowerCase().endsWith('.pdf')) {
        setExtractionStatus(prev => ({
          ...prev,
          progress: 'Extracting PDF content...'
        }));
        const extractor = new PDFExtractor(selectedFile, {
          detectHeaders: true,
          detectLists: true,
          detectTables: true
        });

        const result = await extractor.extract();
        if (!result.success || !result.content) {
          throw new Error(result.error || 'Failed to extract PDF content');
        }

        setContent(result.content);
      } else if (selectedFile.type === 'text/plain') {
        const textContent = await selectedFile.text();
        setContent(textContent);
      }

      setExtractionStatus(prev => ({
        ...prev,
        isProcessing: false,
        progress: 'Processing complete'
      }));

    } catch (error) {
      console.error('File processing error:', error);
      setExtractionStatus(prev => ({
        ...prev,
        isProcessing: false,
        error: error instanceof Error ? error.message : 'Failed to process file'
      }));
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!title.trim() || isProcessing || extractionStatus.isProcessing) return;

    try {
      await onCreateDocument({
        title,
        content: content || '',
        document_type: documentType
      });

      onClose();
    } catch (error) {
      setExtractionStatus(prev => ({
        ...prev,
        error: error instanceof Error ? error.message : 'Failed to create document'
      }));
    }
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
      <div className="w-full max-w-2xl bg-gray-800 rounded-lg shadow-xl">
        <div className="flex items-center justify-between p-6 border-b border-gray-700">
          <h2 className="text-xl font-semibold text-gray-200">Create New Document</h2>
          <button 
            onClick={onClose}
            className="p-2 text-gray-400 hover:text-gray-200 hover:bg-gray-700/50 rounded-lg transition-colors"
          >
            <X size={20} />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="p-6 space-y-6">
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-200">Document Type</label>
            <div className="grid grid-cols-3 gap-2">
              {[
                { type: 'text', icon: FileTextIcon, label: 'Text' },
                { type: 'docx', icon: FileIcon, label: 'Word' },
                { type: 'pdf', icon: FileType, label: 'PDF' }
              ].map(({ type, icon: Icon, label }) => (
                <button
                  key={type}
                  type="button"
                  onClick={() => setDocumentType(type as DocumentType)}
                  className={`flex flex-col items-center gap-2 p-4 rounded-lg border transition-colors ${
                    documentType === type
                      ? 'bg-blue-600 border-blue-500 text-white'
                      : 'bg-gray-700 border-gray-600 text-gray-200 hover:bg-gray-600'
                  }`}
                >
                  <Icon size={20} />
                  <span className="text-sm">{label}</span>
                </button>
              ))}
            </div>
          </div>

          <div className="space-y-2">
            <label htmlFor="title" className="block text-sm font-medium text-gray-200">
              Title
            </label>
            <input
              id="title"
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Document Title"
              className="w-full px-4 py-2 bg-gray-700 text-gray-200 rounded-lg border border-gray-600 
                       placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              required
            />
          </div>

          {documentType === 'text' ? (
            <div className="space-y-2">
              <label htmlFor="content" className="block text-sm font-medium text-gray-200">Content</label>
              <textarea
                id="content"
                value={content}
                onChange={(e) => setContent(e.target.value)}
                placeholder="Document Content"
                className="w-full px-4 py-2 bg-gray-700 text-gray-200 rounded-lg border border-gray-600 
                         placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500
                         focus:border-transparent resize-none min-h-[200px]"
              />
            </div>
          ) : (
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-200">Upload File</label>
              <div className={`relative border-2 border-dashed border-gray-600 rounded-lg p-8
                           hover:border-blue-500 hover:bg-blue-500/5 transition-colors
                           ${extractionStatus.error ? 'border-red-500' : ''}`}>
                <input
                  type="file"
                  onChange={handleFileSelect}
                  accept={`.${documentType}`}
                  className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                  disabled={extractionStatus.isProcessing}
                />
                <div className="flex flex-col items-center text-gray-400">
                  {extractionStatus.isProcessing ? (
                    <>
                      <div className="w-6 h-6 border-2 border-blue-500 border-t-transparent rounded-full animate-spin mb-2" />
                      <span className="text-blue-400">{extractionStatus.progress}</span>
                    </>
                  ) : (
                    <>
                      <Upload size={24} className="mb-2" />
                      <span>
                        {file ? file.name : `Drop your ${documentType.toUpperCase()} file here or click to browse`}
                      </span>
                    </>
                  )}
                </div>
              </div>
              
              {extractionStatus.error && (
                <div className="flex items-center gap-2 text-red-400 mt-2">
                  <AlertCircle size={16} />
                  <span className="text-sm">{extractionStatus.error}</span>
                </div>
              )}

              {content && (documentType === 'pdf' || documentType === 'docx') && (
                <div className="mt-4 p-4 bg-gray-700 rounded-lg">
                  <div className="text-sm font-medium text-gray-200 mb-2">Content Preview:</div>
                  <div 
                    className="prose prose-invert max-h-[200px] overflow-y-auto"
                    dangerouslySetInnerHTML={{ __html: content }}
                  />
                </div>
              )}
            </div>
          )}

          <div className="flex justify-end gap-3 pt-6 border-t border-gray-700">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 bg-gray-700 text-gray-200 rounded-lg hover:bg-gray-600 transition-colors"
              disabled={isProcessing || extractionStatus.isProcessing}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 
                       transition-colors disabled:opacity-50 disabled:cursor-not-allowed flex items-center gap-2"
              disabled={isProcessing || extractionStatus.isProcessing || !title.trim() || !content}
            >
              {(isProcessing || extractionStatus.isProcessing) && (
                <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin" />
              )}
              {isProcessing ? 'Creating...' : 'Create Document'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};