// src/services/APIService.ts
import { firebaseService } from './BrowserFirebaseService';
import type { ChatMessage} from '../types/chat';
import type { Document } from '../types/document';
import { CreateKnowledgeBaseRequest } from '@/types';

export class APIService {
  private readonly baseUrl: string;
  private static instance: APIService | null = null;

  constructor() {
    this.baseUrl = this.determineBaseUrl();
    
    console.log('APIService initialized with:', {
      baseUrl: this.baseUrl,
      environment: import.meta.env.MODE,
      host: window.location.hostname
    });
  }

  private determineBaseUrl(): string {
    if (window.location.hostname === 'testappdev.teamspress.com') {
      return 'https://apidev.teamspress.com';
    }
    
    if (window.location.hostname === 'testappstaging.teamspress.com') {
      return 'https://apistaging.teamspress.com';
    }
    
    if (window.location.hostname === 'testapp.teamspress.com') {
      return 'https://api.teamspress.com';
    }
    
    return 'http://localhost:8787';
  }

  async makeRequest(path: string, options: RequestInit = {}) {
    try {
      const token = await this.getToken();
      
      const fullPath = path.startsWith('/') ? path : `/${path}`;
      const url = `${this.baseUrl}${fullPath}`;
      
      console.log('Making API request:', {
        url,
        method: options.method || 'GET',
        host: window.location.hostname
      });

      const response = await fetch(url, {
        ...options,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          ...options.headers,
        },
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`API Error: ${response.status} - ${errorText}`);
      }

      return response;
    } catch (error) {
      if (error instanceof Error && error.name === 'AbortError') {
        console.log('Request aborted:', path);
        throw error;
      }
      console.error(`Request failed: ${path}`, error);
      throw error;
    }
  }

  static getInstance(): APIService {
    if (!this.instance) {
      this.instance = new APIService();
    }
    return this.instance;
  }

  private async getToken(): Promise<string> {
    return firebaseService.getIdToken(true);
  }

  // Knowledge Base methods
  async getKnowledgeBases() {
    const response = await this.makeRequest('/api/knowledgebases');
    return response.json();
  }

  async createKnowledgeBase(data: CreateKnowledgeBaseRequest) {
    const response = await this.makeRequest('/api/knowledgebases', {
      method: 'POST',
      body: JSON.stringify(data)
    });
    return response.json();
  }

  async deleteKnowledgeBase(kbId: number) {
    return this.makeRequest(`/api/knowledgebases/${kbId}`, {
      method: 'DELETE'
    });
  }

  // Document methods
  async getDocuments(kbId: number) {
    const response = await this.makeRequest(`/api/documents?kb_id=${kbId}`);
    return response.json();
  }

  async createDocument(data: {
    kb_id: number;
    title: string;
    content: string;
    document_type: Document['document_type'];
  }) {
    const response = await this.makeRequest('/api/documents', {
      method: 'POST',
      body: JSON.stringify(data)
    });
    return response.json();
  }

  async deleteDocument(docId: number) {
    return this.makeRequest(`/api/documents/${docId}`, {
      method: 'DELETE'
    });
  }

  async updateDocument(docId: number, data: Partial<{
    title: string;
    content: string;
    document_type: Document['document_type'];
  }>) {
    const response = await this.makeRequest(`/api/documents/${docId}`, {
      method: 'PUT',
      body: JSON.stringify(data)
    });
    return response.json();
  }

  // Chat methods
// Inside APIService class:

async sendChatMessage(
  message: string,
  options: {
    conversationHistory?: ChatMessage[];
    model?: string;
    provider?: string;
    kbId?: string | null;
  },
  signal?: AbortSignal
): Promise<Response> {
  console.log('Sending chat message:', {
    message,
    options,
    hasSignal: !!signal
  });

  // Create the request payload, omitting kbId if it's null
  const payload = {
    message,
    model: options.model,
    provider: options.provider,
    conversationHistory: options.conversationHistory,
    ...(options.kbId ? { kbId: options.kbId } : {})  // Only include kbId if it exists
  };

  console.log('Request payload:', payload);

  const response = await this.makeRequest('/api/ai', {
    method: 'POST',
    body: JSON.stringify(payload),
    signal
  });

  return response;
}
}

// Export a singleton instance
export const apiService = new APIService();