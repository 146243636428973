// src/services/BrowserFirebaseService.ts
import { getAuth, Auth, User } from 'firebase/auth';
import { initializeApp, FirebaseApp, getApps } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getCurrentEnvironment } from '../config/environment';

class BrowserFirebaseService {
  private static instance: BrowserFirebaseService;
  private auth: Auth;
  private app: FirebaseApp;

  private constructor() {
    const config = {
      apiKey: "AIzaSyDOSOsjr_f1XJPbq8Q2O8f1lb7Rdd5bJ60",
      authDomain: "collab365-ai-live.firebaseapp.com",
      projectId: "collab365-ai-live",
      storageBucket: "collab365-ai-live.firebasestorage.app",
      messagingSenderId: "933686756081",
      appId: "1:933686756081:web:023ba7335da3d7e9bc9f50",
      measurementId: "G-FZ91J5EVMJ"
    };

    try {
      // Check if Firebase app is already initialized
      const existingApps = getApps();
      if (existingApps.length > 0) {
        this.app = existingApps[0];
      } else {
        this.app = initializeApp(config);
        if (typeof window !== 'undefined') {
          getAnalytics(this.app);
        }
      }
      
      this.auth = getAuth(this.app);
      
      console.log('Firebase initialized:', {
        environment: getCurrentEnvironment(),
        projectId: config.projectId,
        isInitialized: true
      });
    } catch (error) {
      console.error('Firebase initialization error:', error);
      throw new Error('Failed to initialize Firebase');
    }
  }

  static getInstance(): BrowserFirebaseService {
    if (!BrowserFirebaseService.instance) {
      BrowserFirebaseService.instance = new BrowserFirebaseService();
    }
    return BrowserFirebaseService.instance;
  }

  async getIdToken(forceRefresh = false): Promise<string> {
    const user = this.auth.currentUser;
    if (!user) {
      throw new Error('No authenticated user');
    }
    return user.getIdToken(forceRefresh);
  }

  getCurrentUser(): User | null {
    return this.auth.currentUser;
  }

  isInitialized(): boolean {
    return Boolean(this.auth && this.app);
  }

  getAuth(): Auth {
    return this.auth;
  }

  onAuthStateChanged(callback: (user: User | null) => void) {
    return this.auth.onAuthStateChanged(callback);
  }

  async signOut(): Promise<void> {
    try {
      await this.auth.signOut();
      console.log('User signed out successfully');
    } catch (error) {
      console.error('Error signing out:', error);
      throw error;
    }
  }

  getAuthState() {
    return {
      isInitialized: this.isInitialized(),
      isAuthenticated: Boolean(this.auth.currentUser),
      user: this.auth.currentUser,
      environment: getCurrentEnvironment()
    };
  }
}

// Export singleton instance
export const firebaseService = BrowserFirebaseService.getInstance();

export type FirebaseServiceType = typeof firebaseService;

export default BrowserFirebaseService;