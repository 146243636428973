export interface APIResponse<T> {
    data?: T;
    error?: string;
    message?: string;
  }
  
  export class APIError extends Error {
    constructor(
      message: string,
      public readonly status?: number,
      public readonly code?: string
    ) {
      super(message);
      this.name = 'APIError';
    }
  }
  
  export interface APIConfig {
    baseUrl: string;
    timeout?: number;
    retries?: number;
  }
  
  export type ApiEndpoint = 
    | '/api/ai'
    | '/api/knowledgebases'
    | '/api/documents'
    | '/api/auth-debug';
  
  export interface RequestOptions {
    method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
    body?: any;
    headers?: Record<string, string>;
    signal?: AbortSignal;
  }