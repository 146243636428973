// src/hooks/useAuth.ts
import { useState, useEffect } from 'react';
import { User } from 'firebase/auth';
import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { firebaseService } from '../services/BrowserFirebaseService';

export function useAuth() {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = firebaseService.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
      setLoading(false);
    });
    return unsubscribe;
  }, []);

  const signInWithGoogle = async () => {
    const auth = firebaseService.getAuth();
    const provider = new GoogleAuthProvider();
    
    // Add these OAuth configurations
    provider.setCustomParameters({
      prompt: 'select_account',
      // Add your authorized domain
      login_hint: window.location.hostname,
      // Set redirect URI to current origin
      redirect_uri: `${window.location.origin}`
    });
  
    try {
      return signInWithPopup(auth, provider);
    } catch (error) {
      console.error('Google sign-in error:', error);
      throw error;
    }
  };

  const signOut = () => firebaseService.signOut();

  const getToken = async () => {
    if (!user) return null;
    return user.getIdToken(true);
  };

  return {
    user,
    loading,
    signInWithGoogle,
    signOut,
    getToken
  };
}