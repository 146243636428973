// src/components/TeamTest.tsx
import React from 'react';
import { TeamManagement } from './TeamManagement'; // Change to named import

interface TeamTestProps {
  userContext?: {
    teams?: Array<{
      team_id: number;
      name: string;
      role: string;
    }>;
    lastVerified?: string;
  };
}

export const TeamTest: React.FC<TeamTestProps> = ({ userContext }) => {
  if (!userContext?.teams && !userContext?.lastVerified) {
    return (
      <div className="flex flex-col items-center justify-center p-8">
        <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 max-w-2xl w-full">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg className="h-5 w-5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M8.485 2.495c.873-1.512 3.157-1.512 4.03 0l8.485 14.7c.873 1.512-.218 3.4-2.015 3.4H2.015c-1.797 0-2.888-1.888-2.015-3.4l8.485-14.7zM10 5a1 1 0 011 1v6a1 1 0 11-2 0V6a1 1 0 011-1zm0 10a1 1 0 100 2 1 1 0 000-2z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="ml-3">
              <p className="text-sm text-yellow-700">
                Team management is not available. Please verify your access or contact support.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return <TeamManagement />;
};

export default TeamTest;