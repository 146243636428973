// App.tsx
import { useState, useCallback, useEffect } from 'react';
import { ChatTest } from './components/ChatTest';
import { KnowledgeBaseTest } from './components/KnowledgeBaseTest';
import { TeamTest } from './components/TeamTest';
import { useAuth } from './hooks/useAuth';
import './styles/TestHarness.css';
import { Info, AlertCircle } from 'lucide-react';
import { apiService } from './services/APIService';
import { VersionFooter } from './components/VersionFooter';

interface AuthDebugResponse {
  status: string;
  message: string;
  user?: {
    id: string;
    email?: string;
    emailVerified?: boolean;
    authTime: string;
    createdAt: string;
  };
  environment?: {
    environment: string;
    hasProjectId: boolean;
    projectId: string;
    hasFirebaseKey: boolean;
  };
  teams?: Array<{
    team_id: number;
    name: string;
    role: string;
  }>;
  error?: string;
}

interface UserContextInfo {
  teams?: Array<{
    team_id: number;
    name: string;
    role: string;
  }>;
  lastVerified?: string;
}

function App() {
  const [activeTab, setActiveTab] = useState('chat');
  const { user, loading, signInWithGoogle, signOut, getToken } = useAuth();
  const [authDebugResponse, setAuthDebugResponse] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [userContext, setUserContext] = useState<UserContextInfo | null>(null);

  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
    setError(null);
  };

  const handleAuthDebug = useCallback(async (silent = false) => {
    try {
      if (!silent) setError(null);
      const token = await getToken();
      if (!token) {
        setAuthDebugResponse('No auth token available - user not logged in');
        return;
      }
  
      console.log('Making auth debug request...');
      
      // Use APIService instead of direct fetch
      const response = await apiService.makeRequest('/api/auth-debug', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      });
  
      const data: AuthDebugResponse = await response.json();
  
      // Update user context with teams data
      setUserContext({
        teams: data.teams,
        lastVerified: new Date().toISOString()
      });
  
      if (!silent) {
        setAuthDebugResponse(JSON.stringify(data, null, 2));
        
        if (data.status === 'success') {
          setError('Authentication verified successfully!');
          setTimeout(() => setError(null), 3000);
        }
      }
  
    } catch (error) {
      console.error('Auth debug error:', error);
      const errorMessage = error instanceof Error ? error.message : 'Unknown error';
  
      if (errorMessage.includes('token')) {
        setError('Authentication token error - please sign in again');
        await signOut();
      } else {
        setError(`Error: ${errorMessage}`);
      }
    }
  }, [getToken, signOut]);

  useEffect(() => {
    if (user && !userContext) {  // Only call if user exists and context doesn't
      handleAuthDebug(true);
    }
  }, [user]);

  if (loading) {
    return (
      <div className="loading">
        <div className="loading-spinner"></div>
        <p>Loading authentication status...</p>
      </div>
    );
  }

  if (!user) {
    return (
      <div className="login-container">
        <h2 className="login-title">Test Harness</h2>
        <p className="login-subtitle">Please sign in to continue</p>
        <button
          onClick={signInWithGoogle}
          className="login-button"
          disabled={loading}
        >
          {loading ? 'Signing in...' : 'Sign in with Google'}
        </button>
        {error && <div className="login-error">{error}</div>}
      </div>
    );
  }

  return (
    <div className="test-harness">
      <header className="header">
        <div className="user-info">
          {user.photoURL && (
            <img
              src={user.photoURL}
              alt={user.displayName || 'User avatar'}
              className="avatar"
            />
          )}
          <div className="user-details">
            <span className="user-name">{user.displayName || user.email}</span>
            {user.email && <span className="user-email">{user.email}</span>}
            {userContext?.teams && userContext.teams.length > 0 && (
              <div className="user-teams">
                {userContext.teams.map(team => (
                  <span key={team.team_id} className="team-badge">
                    {team.name} ({team.role})
                  </span>
                ))}
              </div>
            )}
          </div>
        </div>

        <div className="tabs">
          <button
            onClick={() => handleTabChange('chat')}
            className={`tab ${activeTab === 'chat' ? 'active' : ''}`}
          >
            Chat Test
          </button>
          <button
            onClick={() => handleTabChange('kb')}
            className={`tab ${activeTab === 'kb' ? 'active' : ''}`}
          >
            Knowledge Base
            {userContext?.teams && userContext.teams.length > 0 && (
              <span className="ml-2 text-xs bg-blue-500 text-white px-2 py-0.5 rounded-full">
                Team Access
              </span>
            )}
          </button>
          <button
            onClick={() => handleTabChange('team')}
            className={`tab ${activeTab === 'team' ? 'active' : ''}`}
          >
            Team Management
          </button>
          <button
            onClick={() => handleAuthDebug(false)}
            className="tab debug-tab"
            title="Verify authentication and refresh team access"
          >
            Auth Debug
            {userContext?.lastVerified && (
              <span className="ml-2">
                <Info size={14} className="inline" />
              </span>
            )}
          </button>
          <button
            onClick={signOut}
            className="sign-out"
            title="Sign out"
          >
            Sign Out
          </button>
        </div>
      </header>

      {error && (
        <div className={`notification ${error.includes('successfully') ? 'success' : 'error'}`}>
          <span className="flex items-center gap-2">
            <AlertCircle size={16} />
            {error}
          </span>
          <button onClick={() => setError(null)} className="notification-close">
            ×
          </button>
        </div>
      )}

      <main className="content">
        {activeTab === 'chat' ? (
          <ChatTest />
        ) : activeTab === 'kb' ? (
          <KnowledgeBaseTest userContext={userContext ?? undefined} />
        ) : activeTab === 'team' ? (
          <TeamTest userContext={userContext ?? undefined} />
        ) : null}
      </main>

      {authDebugResponse && (
        <div className="auth-debug-response">
          <div className="debug-header">
            <h3>Auth Debug Response</h3>
            <button
              onClick={() => setAuthDebugResponse(null)}
              className="close-button"
              title="Close debug response"
            >
              ×
            </button>
          </div>
          <pre>{authDebugResponse}</pre>
        </div>
      )}

      <VersionFooter />
    </div>
  );
}

export default App;